import React from 'react';
import { useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// import TagManager from 'react-gtm-module';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import {
  Paper,
  Box,
  CssBaseline,
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
  useMediaQuery,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';
import { getDesignTokens, getThemedComponents } from 'theme/Theme';
import { ColorModeContext } from 'config/color-context';
import Page from 'pages/Page';
import Blog from 'pages/Blog';
import Post from 'pages/Post';
import Category from 'pages/Category';
import SubNavigation from 'components/navigation/header-sub-navigation/HeaderSubNavigation';
import Header from 'components/_base/header/Header';
import Footer from 'components/_base/footer/Footer';
import ScrollToTop from 'components/scroll-to-top/ScrollToTop';
import './App.scss';
import CanvasParticles from 'components/canvas/CanvasParticles';
import ReactGA from "react-ga4";
const TRACKING_ID = "G-GVLNZCGR0V";

// const client = new ApolloClient({
//   uri: `${process.env.REACT_APP_ENDPOINT}/graphql`,
//   fetchOptions: {
//     mode: 'no-cors',
//   },
//   cache: new InMemoryCache(),
// });

// const tagManagerArgs = {
//   gtmId: process.env.REACT_APP_GTM_KEY,
// };
// TagManager.initialize(tagManagerArgs);

export default function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [mode, setMode] = React.useState();

  React.useEffect(() => {
    setMode(prefersDarkMode ? 'dark' : 'light');
  }, [prefersDarkMode]);

  React.useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: "/", title: "Home Page" });
}, [])

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
      },
    }),
    []
  );

  let theme = React.useMemo(
    () =>
      createTheme(deepmerge(getDesignTokens(mode), getThemedComponents(mode))),
    [mode]
  );
  theme = responsiveFontSizes(theme);

  const Main = styled('main')(({ theme }) => ({
    flexGrow: 1,
    position: 'relative',
  }));

  window.dataLayer.push({
    event: 'pageview',
  });
  // console.log('pathname', location.pathname);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}
        >
          <Router>
            <>
              <ScrollToTop />
              <Header />
              <Main>
                <Routes>
                  {/* <Route exact path="/" element={<Page />}></Route>
                  <Route exact path="/:slug" element={<Page />}></Route> */}
                  {['/', '/:slug'].map((path, index) => {
                    return path === '/' ? (
                      <Route
                        exact
                        path={path}
                        element={[
                          // <CanvasParticles key={index} />,
                          <Page key={index + 1} />,
                        ]}
                        key={index}
                      />
                    ) : (
                      <Route path={path} element={<Page />} key={index} />
                    );
                  })}
                  <Route exact path="/blog" element={<Blog />}></Route>
                  <Route path="/blog/:slug" element={<Post />}></Route>
                  <Route path="/category/:slug" element={<Category />}></Route>
                </Routes>
              </Main>
              <Footer />
            </>
          </Router>
        </Paper>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

/*
TODO Required:

*/

/*
TODO Optional:

Later I will setup that you will get notifications (to your tazlari email and phone) if someone sends to email or mailing list

Add pagination to Blog and Articles
Contact form add Recaptcha (https://github.com/codebubb/react-contact-form-tutorial/blob/main/src/App.tsx)
Contact form adds entry to Strapi
+Sharing on social media (use from NuxtJs?)

TODO: Refactor:
Newsletter form
*/
/*

xs, extra-small: 0px
sm, small: 600px
md, medium: 900px
lg, large: 1200px
xl, extra-large: 1536px

*/
