import React, { useState } from 'react';
import Markdown from 'markdown-to-jsx';
import { useTheme } from '@mui/material/styles';
import {
  TextField,
  Button,
  Box,
  Typography,
  Container,
  Grid,
  Link,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { green, red } from '@mui/material/colors';
import Fab from '@mui/material/Fab';
import EmailIcon from '@mui/icons-material/Email';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import 'components/contact-form/contact-form.scss';

export default function ContactForm(props) {
  console.log('CONTACTFORM.js 1', props);
  const { props: data } = props;

  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [error, setError] = React.useState(false);

  const MarkdownLink = ({ children, ...props }) => (
    <Link {...props}>{children}</Link>
  );

  const style = {
    textField: {
      mb: 2,
    },
    formField: {
      minWidth: { xs: '100%', md: 400 },
    },
    formButton: {
      ...(success && {
        bgcolor: green[500],
        '&:hover': {
          bgcolor: green[700],
        },
      }),
      ...(error && {
        bgcolor: red[500],
        '&:hover': {
          bgcolor: red[700],
        },
      }),
    },
  };

  const [name, setName] = useState('');
  const [nameError, setNameError] = useState(false);

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  const [message, setMessage] = useState('');
  const [messageError, setMessageError] = useState(false);

  const [emailStatus, setEmailStatus] = useState('');
  const [emailStatusText, setEmailStatusText] = useState('');

  const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);

  const formLocation = "tazmin.dev";

  const resetInputFields = () => {
    setName('');
    setEmail('');
    setMessage('');
  };

  const theme = useTheme();

  const handleSubmit = (event) => {
    event.preventDefault();
    setNameError(false);
    setEmailError(false);
    setMessageError(false);

    if (name === '') {
      setNameError(true);
    }
    if (email === '') {
      setEmailError(true);
    }
    if (message === '') {
      setMessageError(true);
    }

    if (name && email && message) {
      setIsEmailSubmitted(false);

      if (!loading) {
        setSuccess(true);
        setLoading(false);
      }

      console.log("+++++++++++++++++++FETCH from", formLocation)


      fetch(`${process.env.REACT_APP_STRAPI_API}/email`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify({ name, email, message, formLocation }),
      })
        .then((response) => {
          setIsEmailSubmitted(true);

          return response.json().then((data) => {
            console.log('ContactForm Then Response: ', data);
            setSuccess(true);
            setError(false);
            setLoading(false);
            const { success, error } = data;
            setEmailStatus(success ? 'success' : 'error');
            setEmailStatusText(success || error);
            resetInputFields();

            return data;
          });
        })
        .catch((error) => {
          console.log('ContactForm Then Error: ', error);
          setError(true);
          console.log('Error: ', error);
        });
    }
  };
  return (
    <React.Fragment>
      <Container maxWidth="none" className="introduction-container">
        <Grid
          container
          maxWidth="lg"
          sx={{
            mx: 'auto',
            py: { xs: 3, md: 20 },
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          className="introduction-grid"
        >
          <Box
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
            sx={{
              maxWidth: { xs: '100%', md: '75%' },
            }}
            className="contact-form"
          >
            <Typography
              component={data.isHeadingH1Tag ? 'h1' : 'h3'}
              variant="h2"
              sx={{
                textTransform: 'uppercase',
                fontWeight: 500,
                mb: 6,
                textAlign: 'center',
              }}
            >
              {data.heading}
            </Typography>
            <Typography
              component="div"
              sx={{
                mb: { xs: 6, md: 12 },
                textAlign: 'center',
              }}
            >
              <Markdown
                options={{
                  forceBlock: true,
                  disableParsingRawHTML: false,
                  overrides: {
                    a: {
                      component: MarkdownLink,
                      props: {
                        target: '_blank',
                      },
                    },
                  },
                }}
              >
                {data.description}
              </Markdown>
            </Typography>
          </Box>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            autoComplete="off"
            sx={{
              maxWidth: { xs: '100%', md: '50%' },
              display: 'flex',
              flexDirection: 'column',
              mx: 'auto',
              width: '100%',
            }}
            className="contact-form"
          >
            <TextField
              onChange={(event) => setName(event.target.value)}
              value={name}
              sx={{ ...style.textField }}
              label="Name"
              variant="filled"
              size="small"
              required
              error={nameError}
            />
            <TextField
              onChange={(event) => setEmail(event.target.value)}
              value={email}
              sx={{ ...style.textField }}
              label="Email"
              variant="filled"
              size="small"
              required
              error={emailError}
            />
            <TextField
              onChange={(event) => setMessage(event.target.value)}
              value={message}
              sx={{ ...style.textField }}
              label="Message"
              variant="filled"
              size="small"
              multiline
              rows={4}
              required
              error={messageError}
            />
            <Button
              sx={style.formButton}
              type="submit"
              color="primary"
              variant="contained"
              size="large"
              disabled={loading}
              className="_Button"
            >
              Submit
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: green[500],
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
            {isEmailSubmitted && (
              <Box sx={{ mt: 2 }}>
                <Typography
                  sx={{
                    textAlign: 'left',
                    display: 'block',
                    color:
                      emailStatus === 'success'
                        ? theme.palette.success.main
                        : theme.palette.error.main,
                  }}
                >
                  {emailStatusText}
                </Typography>
              </Box>
            )}
          </Box>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
